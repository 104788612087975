import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SystemSettingsService } from '@core/services/system-settings.service';
import { ModulesActivation } from 'src/app/system/models/system-settings';

export const moduleGuard: CanActivateFn = async (route, state) => {
    const systemSettingsService = inject(SystemSettingsService);
    const moduleName = route.data['moduleName']  as keyof ModulesActivation;
    const globalSettings = await systemSettingsService.GlobalSettings();
    if (globalSettings.modulesActivation[moduleName]) return true;
    return false;
};


