import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ServiceWorkerModule } from '@angular/service-worker';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { CACHE_SIZE_UNLIMITED, getFirestore, initializeFirestore, persistentLocalCache, persistentMultipleTabManager, provideFirestore } from '@angular/fire/firestore';
import { IntercomModule } from '@supy-io/ngx-intercom';
import * as Sentry from "@sentry/angular";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { provideUserIdleConfig } from 'angular-user-idle';

import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from '../material-module';

import { AppComponent } from './app.component';

import { AccountingService } from './core/services/accounting.service';
import { AppSettingsService } from './core/services/app-settings.service';
import { WarehouseService } from './shared/services/warehouse.service';
import { OnlineOfflineService } from './core/services/online-offine.service';
import { FeaturesConfigService } from './core/services/features-config.service';
import { PotentialFraudDetectorService } from './core/services/potential-fraud-detector.service';

import { MoneyHelper, ErrorMessageFormatter, IPHelper, SoundHelper, DexieDbProvider } from '@shared';
import { SwitchLanguageService } from '@core';
import { AppErrorHandler } from '@core/handlers/app.error-handler';

import { environment } from '../environments/environment';

Sentry.init({
  dsn: environment.sentry.dsn,
  ignoreErrors: environment.sentry.ignoreErrors
});

export function translateHttpLoaderFactory(handler: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(handler));
}

export function appSettingsProviderFactory(provider: AppSettingsService) {
  return () => provider.load();
}

export function featuresFlagsProviderFactory(provider: FeaturesConfigService) {
  return () => provider.load();
}

export function translateProviderFactory(provider: SwitchLanguageService) {
  return () => provider.load();
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    KeyboardShortcutsModule.forRoot(),
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: translateHttpLoaderFactory, deps: [HttpBackend] } }),
    HomeModule,
    AuthModule,
    CoreModule,
    SharedModule,
    MaterialModule,
    AppRoutingModule,
    ServiceWorkerModule.register('sw-master.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    IntercomModule.forRoot({
      appId: environment.intercomAppId,
      updateOnRouterChange: true
    })
  ],
  providers: [
    provideAnimationsAsync(),
    provideAuth(() => getAuth()),
    provideFirebaseApp(() => {
      const firebaseApp = initializeApp(environment.firebase);
      initializeFirestore(firebaseApp, {
        ignoreUndefinedProperties: true,
        localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager(), cacheSizeBytes: CACHE_SIZE_UNLIMITED }),
      });
      return firebaseApp;
    }),
    provideFirestore(() => getFirestore()),
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) and `ping` is 120 (2 minutes).
    provideUserIdleConfig({ idle: 300, timeout: 1, ping: 0 }),
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: APP_INITIALIZER, useFactory: appSettingsProviderFactory, deps: [AppSettingsService], multi: true },
    { provide: APP_INITIALIZER, useFactory: featuresFlagsProviderFactory, deps: [FeaturesConfigService], multi: true },
    { provide: APP_INITIALIZER, useFactory: translateProviderFactory, deps: [SwitchLanguageService], multi: true },
    DexieDbProvider,
    PotentialFraudDetectorService,
    AccountingService,
    AppSettingsService,
    WarehouseService,
    OnlineOfflineService,
    FeaturesConfigService,
    CookieService,
    IPHelper,
    MoneyHelper,
    SoundHelper,
    ErrorMessageFormatter
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


