import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/components/home/home.component';
import { moduleGuard } from '@core/guards/module.guard';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'pos', loadChildren: () => import('./point-of-sale/point-of-sale.module').then(m => m.PointOfSaleModule), canActivate: [moduleGuard], data: { moduleName: 'pointOfSale' } },
  { path: 'purchasing', loadChildren: () => import('./purchasing/purchasing.module').then(m => m.PurchasingModule), canActivate: [moduleGuard], data: { moduleName: 'purchasing' } },
  { path: 'invoicing', loadChildren: () => import('./invoicing/invoicing.module').then(m => m.InvoicingModule), canActivate: [moduleGuard], data: { moduleName: 'invoicing' } },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  { path: 'manufacturing', loadChildren: () => import('./manufacturing/manufacturing.module').then(m => m.ManufacturingModule), canActivate: [moduleGuard], data: { moduleName: 'manufacturing' } },
  { path: 'fixed-assets', loadChildren: () => import('./fixed-assets/fixed-assets.module').then(m => m.FixedAssetsModule), canActivate: [moduleGuard], data: { moduleName: 'fixedAssets' } },
  { path: 'data', loadChildren: () => import('./data/data.module').then(m => m.DataModule) },
  { path: 'system', loadChildren: () => import('./system/system.module').then(m => m.SystemModule) },
  { path: 'samples', loadChildren: () => import('./samples/samples.module').then(m => m.SamplesModule) },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
