import { environment } from "src/environments/environment";
import * as sha256 from "js-sha256";
import { Organization, UserProfile } from "./user-profile";

export class IntercomEdaraConfig {
    app_id: string = environment.intercomAppId
    custom_launcher_selector?: string;
    name?: string;
    email?: string;
    alignment: "right" | "left" | undefined;
    horizontal_padding?: number;
    vertical_padding?: number;
    language_override: string;
    hide_default_launcher: boolean = false;
    user_hash?: string;
    TenantID?: string;
    TenantName?: string;
    constructor(isAuthenticated: boolean, lang: string, currentUser?: UserProfile, currentOrganization?: Organization, horizontal_padding?: number, vertical_padding?: number ) {
        this.language_override = lang === "en" ? "en" : "ar-EG";
        this.alignment = lang === "en" ? "left" : "right";
        this.horizontal_padding = horizontal_padding;
        this.vertical_padding = vertical_padding;
        if (isAuthenticated && currentUser) {
            this.custom_launcher_selector = "#edarachat";
            this.hide_default_launcher = true;
            let _HMAC_VALUE = sha256.sha256.hmac(
                "a_szaL6W7Oclf4X-8tUtv4RgNzF1TZdfRjmuuyCs",
                currentUser.email
            );
            this.name = currentUser.nickname // Full name
            this.email = currentUser.email
            this.user_hash = _HMAC_VALUE;
            this.TenantID = currentOrganization?.id;
            this.TenantName = currentOrganization?.name;
        }
        else {
            this.vertical_padding = 70;
        }
    }
}
