import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { HomeComponent } from './components/home/home.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        HomeComponent
    ],
    imports: [
        CommonModule,
        TranslateModule
    ]
})
export class HomeModule {
}
