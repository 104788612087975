import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '@auth';
import { LockService } from './core/services/lock.service';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { Router, NavigationEnd } from '@angular/router';
import { VersionCheckService } from './core/services/version-check.service';
import { environment } from 'src/environments/environment';
import { SwitchLanguageService } from './core/services/switch-language.service';
import { OnlineOfflineService } from './core/services/online-offine.service';
import * as Sentry from "@sentry/angular";
import { IntercomEdaraConfig } from './shared/models/intercom-config';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { PotentialFraudDetectorService } from './core/services/potential-fraud-detector.service';
import { AppService } from './core/services/app.service';
import { HelpersService } from '@shared';
import { PagesMenuService } from '@shared/services/pages-menu.service';
import { PageMenuDateService } from '@shared/services/page-menu-date.service';

// Declare gtag as a function to set and sent the events
declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'Edara';
  intercomMessages?: number;
  shortcuts: ShortcutInput[] = [];
  isAuthenticated = false;
  showLockScreen = false;
  fullLayoutEnabled = false;

  constructor(
    public appService: AppService,
    public helpersService: HelpersService,
    public onlineOfflineService: OnlineOfflineService,
    private authService: AuthService,
    private lockService: LockService,
    private versionCheckService: VersionCheckService,
    private switchLanguageService: SwitchLanguageService,
    private router: Router,
    private translateService: TranslateService,
    private intercom: Intercom,
    private potentialFraudDetectorService: PotentialFraudDetectorService,
    private menuService: PagesMenuService,
    private pageMenuDateService: PageMenuDateService
  ) {
    this.switchLanguageService.useCurrentLanguage();
    this.appService.init();
    if (this.onlineOfflineService.isOnline) {
      authService.handleAuthentication();
    }
  }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();

    if (this.onlineOfflineService.isOnline) {
      this.versionCheckService.initVersionCheck(window.location.origin + environment.versionCheckURL);

      if (localStorage.getItem('isLoggedIn') === 'true') {
        this.authService.renewToken();
        this.authService.renewFirebaseToken();
      }
    }

    if (this.authService.isAuthenticated()) {
      // Identify user for Google Analytics (Set the user ID using signed-in user_id)
      gtag('set', 'userId', this.authService.currentUserEmail);

      this.setUserContextToSentry();

      this.initIntercom();

      this.lockService.passcodeLoaded.subscribe({
        next: () => {
          this.lockService.updateTimer();
          // Start watching for user inactivity.
          if (this.lockService.isExpired()) {
            this.lockService.show();
          } else {
            this.lockService.startWatching();
          }
        }
      });

      this.appService.setBodyPadding();

      this.menuService.loadStarredPagesList();

      this.pageMenuDateService.loadOpenedPagesList();
    }

    this.authService.userLoggedIn$.subscribe({
      next: () => {
        this.isAuthenticated = true;

        // Identify user for Google Analytics (Set the user ID using signed-in user_id)
        gtag('set', 'userId', this.authService.currentUserEmail);

        this.setUserContextToSentry();

        // load intercom after login
        this.initIntercom();

        // set lockscreen timer after login If it is not exist
        this.lockService.initTimer();

        this.appService.setBodyPadding();

        this.menuService.loadStarredPagesList();

        this.pageMenuDateService.loadOpenedPagesList();
      }
    });

    this.appService.onFullLayoutEnabled().subscribe({
      next: (enabled: boolean) => this.fullLayoutEnabled = enabled
    });

    this.lockService.change.subscribe({
      next: (isLocked: boolean) => {
        this.showLockScreen = isLocked;
      }
    });

    this.router.events.subscribe({
      next: (event) => {
        if (this.onlineOfflineService.isOnline) {
          if (event instanceof NavigationEnd && !event.url.startsWith('/callback')) {
            // Send page views to Google Analytics
            gtag('set', 'page', event.urlAfterRedirects);
            gtag('send', 'pageview');

            // Refresh access token on page navigation
            this.authService.renewToken();
            this.authService.renewFirebaseToken();
          }
        }
      }
    });

    this.onlineOfflineService.connectionChanged.subscribe({
      next: () => {
        // Show fresh chat if online, otherwise hide it
        const edaraChat = document.getElementById('edarachat');
        if (edaraChat) {
          edaraChat.style.visibility = this.onlineOfflineService.isOnline ? 'visible' : 'hidden';
        }
      }
    });

    //Initialize Potential Fraud Detector Service
    this.potentialFraudDetectorService.start();
  }

  ngAfterViewInit() {
    this.initShortcuts();
  }

  toggleSideNav() {
    if (this.appService.state.navState === 'expanded')
      this.appService.handleNavState('collapsed');
    else if (this.appService.state.navState === 'collapsed')
      this.appService.handleNavState('closed');
    else if (this.appService.state.navState === 'closed')
      this.appService.handleNavState('expanded');
  }

  private initIntercom() {
    const intercomConfig = new IntercomEdaraConfig(
      this.isAuthenticated,
      this.translateService.currentLang,
      this.authService.userProfile,
      this.authService.currentOrganization,
      this.appService.state.messengerState.horizontal_padding,
      this.appService.state.messengerState.vertical_padding
    );
    this.intercom.boot(intercomConfig);
    this.translateService.onLangChange.subscribe({
      next: (translateObj: LangChangeEvent) => {
        this.intercom.update({
          alignment: translateObj.lang === 'en' ? 'right' : 'left',
          language_override: translateObj.lang === 'en' ? 'en' : 'ar-EG'
        });
      }
    });
    this.intercom.onUnreadCountChange(
      messages => (this.intercomMessages = messages)
    );
  }

  private setUserContextToSentry() {
    Sentry.setUser({
      id: this.authService.currentUserId,
      email: this.authService.currentUserEmail,
      username: this.authService.currentUserEmail,
    });

    if (this.authService.currentTenant) {
      Sentry.setExtras({
        appVersion: environment.version,
        organizationId: this.authService.currentTenant,
        organizationName: this.authService.currentOrganization?.name
      });
    }
    Sentry.setTag('Channel', 'PWA');
  }

  private initShortcuts() {
    this.shortcuts.push(
      {
        key: ['F4', 'cmd + l'],
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        label: 'Navigation',
        description: 'Lock',
        command: () => this.lockService.show(),
        preventDefault: true
      },
      {
        key: ['cmd + shift + F4'],
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        label: 'Navigation',
        description: 'Logout',
        command: () => this.authService.logout(),
        preventDefault: true
      }
    );
  }
}
